export const OAuthFilters = {
    OAUTH_SAVE: 'LOCAL.OAUTH.SAVE',
    OAUTH_REFRESH: 'LOCAL.OAUTH.REFRESH',
    OAUTH_CLEAN: 'LOCAL.OAUTH.CLEAN',
};

export const OAuthScopes = {
    PUBLIC: 'public',
    USER: 'user',
};

export const save = (accessToken, refreshToken, expiresIn, scope) => ({
    type: OAuthFilters.OAUTH_SAVE,
    accessToken, refreshToken, expiresIn, scope
});

export const refresh = (accessToken, expiresIn) => ({
    type: OAuthFilters.OAUTH_REFRESH,
    accessToken, expiresIn
});

export const clean = () => ({
    type: OAuthFilters.OAUTH_CLEAN
});
