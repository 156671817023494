export const OrderFilters = {
    SET_ORDER_ID: 'LOCAL.ORDER.SET_ID',
    SET_ITEM: 'LOCAL.ORDER.SET_ITEM',
    SET_DELIVERY_FEE: 'LOCAL.ORDER.SET_DELIVERY_FEE',
    UPDATE_NOTE: 'LOCAL.ORDER.UPDATE_NOTE',
    REMOVE_ITEM: 'LOCAL.ORDER.REMOVE_ITEM',
    CLEAN: 'LOCAL.ORDER.CLEAN',
};

export const setOrderId = id => ({
    type: OrderFilters.SET_ORDER_ID,
    id
});

export const setItem = (id, menuItemId, quantity, variants = [], note = undefined) => ({
    type: OrderFilters.SET_ITEM,
    id,
    menuItemId,
    quantity,
    variants,
    note
});

export const setDeliveryFee = (deliveryFee) => ({
    type: OrderFilters.SET_DELIVERY_FEE,
    deliveryFee
});

export const removeItem = id => ({
    type: OrderFilters.REMOVE_ITEM,
    id
});

export const updateNote = note => ({
    type: OrderFilters.UPDATE_NOTE,
    note
});

export const clean = () => ({
    type: OrderFilters.CLEAN
});
