import React from "react";
import { connect } from "react-redux";
import { InMemoryContext, LocalContext } from "./redux/store";
import Container from "@material-ui/core/Container";
import ApiClient from "./client/api";
import * as posActions from "./redux/actions/inMemory/pos";
import * as menuActions from "./redux/actions/inMemory/menu";
import * as commonActions from "./redux/actions/local/common";
import * as orderActions from "./redux/actions/local/order";
import * as layoutActions from "./redux/actions/inMemory/layout";
import * as styleActions from "./redux/actions/inMemory/style";
import * as logoActions from "./redux/actions/inMemory/logo";
import * as openSummaryActions from "./redux/actions/inMemory/openSummary";
import { compose } from "redux";
import Navbar from "./components/Layout/Header";
import LoaderFullPage from "./components/LoaderFullPage";
import { Category, MenuItem } from "./redux/vo/menu";
import LazyLoad from "./components/LazyLoad";
import Grid from "@material-ui/core/Grid";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import routes from "./routes";
import RouteWithSubRoutes from "./components/RouteWithSubRoutes";
import { loadGoogleMaps } from "./client/google";
import StatusDisabled from "./view/StatusDisabled";
import { withStyles } from "@material-ui/core";
import logo from "./assets/logo-horizontal.png";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import styled, { css } from "styled-components";
import { Trans } from 'react-i18next';
import { CookieBanner } from "./components/CookieBanner";

const ContextBar = React.lazy(() =>
    import("./components/ContextBar/ContextBar")
);
const StepBar = React.lazy(() => import("./components/StepBar"));
const Error404 = React.lazy(() => import("./view/Error404"));

const styles = (theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
    },
    main: {
        marginBottom: theme.spacing(5),
    },
    footer: {
        padding: theme.spacing(3, 0),
        marginTop: "auto",
        backgroundColor: "#fff",
        borderTop: "1px solid #ddd",
    },
});

class App extends React.Component {
    client = new ApiClient();

    state = {
        loading: false,
        error: false,
        company: {
            name: "",
            billingAddress: "",
            vat: "",
        },
        address: "",
    };

    componentDidMount() {
        loadGoogleMaps();
        this.setState(
            {
                loading: true,
            },
            this.loadSiteInfo
        );
    }

    loadSiteInfo = () => {
        this.client
            .siteInfo()
            .then(({ data: infoResponse }) => {
                this.props.setSiteInfoFromResponse(infoResponse);
                this.props.setLayoutOptions(infoResponse.layout);
                this.props.setStyle(infoResponse.style);
                this.props.setLogo(infoResponse.logo);
            })
            .then(() =>
                Promise.all([this.client.posDetail(), this.client.menuDetail()])
            )
            .then((promises) => {
                this.props.setPosInfoFromResponse(promises[0].data);
                this.props.setMenuInfoFromResponse(promises[1].data);

                this.setState({
                    ...this.state, company: {
                        billingAddress: promises[0].data?.company?.billingAddress?.text,
                        name: promises[0].data.company.name,
                        vat: promises[0].data.company.vat,
                    }, address: promises[0].data.address.text,
                });

                document.title = `${promises[0].data.name} | By Deliverart`;

                // Clear all canceled/broken menu items
                const menuItemIds = promises[1].data.items.map(
                    (item) => item.id
                );
                this.props.orderItems
                    .filter((item) => !menuItemIds.includes(item.menuItemId))
                    .forEach(this.props.removeItem);
            })
            .catch((e) =>
                this.setState({ error: true }, () => console.error(e))
            )
            .finally(() => this.setState({ loading: false }));
    };

    render() {
        const { loading, error } = this.state;
        const { classes } = this.props;

        if (loading) {
            return <LoaderFullPage />;
        }

        if (error) {
            return <StatusDisabled />;
        }

        return (
            <div className={classes.root}>
                <Router>
                    <React.Suspense fallback={<span />}>
                        <Navbar onClickCart={this.props.openSummary} />
                        <MainWrapper component="main">
                            <Header>
                                <Grid item xs={12}>
                                    <LazyLoad component={ContextBar} />
                                </Grid>
                                <Grid item xs={12}>
                                    <LazyLoad component={StepBar} />
                                </Grid>
                            </Header>
                            <BodyWrapper>
                                <Switch>
                                    {routes.map((route, idx) => (
                                        <RouteWithSubRoutes
                                            key={idx}
                                            {...route}
                                        />
                                    ))}
                                    <Route path="*">
                                        <Error404 />
                                    </Route>
                                </Switch>
                            </BodyWrapper>
                        </MainWrapper>
                        <StyledFooter className={classes.footer}>
                            <Container>
                                <Grid container>
                                    <Grid item xs={4} spacing={2}>
                                        <StyledTypographyBold>{this.state.company.name}</StyledTypographyBold>
                                        <StyledTypographyBold><Trans i18nKey="translation:address.registered_office" />: {this.state.company.billingAddress}</StyledTypographyBold>
                                        <StyledTypographyBold><Trans i18nKey="translation:address.operational_headquarters" />: {this.state.address}</StyledTypographyBold>
                                        {this.state.company.vat && <StyledTypographyBold>P.IVA: {this.state.company.vat}</StyledTypographyBold>}
                                        <StyledTypography>
                                            <a href="https://www.deliverart.it/licenza-duso-sito-ecommerce/">
                                                Termini e condizioni d'uso
                                            </a>
                                        </StyledTypography>
                                        <StyledTypography>
                                            <a href="https://www.deliverart.it/informativa-sulla-privacy-modulo-ordini-online/">
                                                Informativa Privacy
                                            </a>
                                        </StyledTypography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Box textAlign="right">
                                            <Typography
                                                align="right"
                                                component="p"
                                                variant="caption"
                                                gutterBottom
                                            >
                                                Powered by{" "}
                                                <a
                                                    href="https://deliverart.it"
                                                    target="_blank"
                                                >
                                                    Deliverart
                                                </a>{" "}
                                                © {new Date().getFullYear()}
                                            </Typography>
                                            <StyledTypography>
                                                <a
                                                    href="https://www.iubenda.com/privacy-policy/8283529"
                                                    target="_blank"
                                                >
                                                    Privacy Policy
                                                </a>
                                                {" | "}
                                                <a
                                                    href="https://www.iubenda.com/privacy-policy/8283529/cookie-policy"
                                                    target="_blank"
                                                >
                                                    Cookie Policy
                                                </a>
                                            </StyledTypography>
                                            <Typography
                                                align="right"
                                                component="p"
                                                variant="caption"
                                            >
                                                Deliverart Srl P.IVA 15110261003
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Container>
                        </StyledFooter>
                        <CookieBanner />
                    </React.Suspense>
                </Router>
            </div>
        );
    }
}

const StyledFooter = styled.footer`
    a {
        color: #3B9684;
    }
`;

const StyledTypography = styled(Typography)`
    font-size: 0.75rem;
`;

const StyledTypographyBold = styled(Typography)`
    font-size: 0.75rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
`;

const MainWrapper = styled(Container)`
    margin-bottom: 2rem;
`;

const ContainerStyle = css`
    padding: 0;
`;

const Header = styled(Container)`
    ${ContainerStyle}
`;

const BodyWrapper = styled(Container)`
    ${ContainerStyle}
`;

const Footer = styled.footer``;

const mapInMemoryStateToProps = (state) => ({
    hasPos: state.pos !== null,
});

const mapInMemoryDispatchToProps = (dispatch) => ({
    setPosInfoFromResponse: (posResponse) =>
        dispatch(
            posActions.setInfo(
                posResponse.name,
                posResponse.country,
                posResponse.company,
                posResponse.address
            )
        ),
    setLayoutOptions: (layoutOptions) =>
        dispatch(layoutActions.setLayoutOptions(layoutOptions)),
    setStyle: (style) => dispatch(styleActions.setStyle(style)),
    setLogo: (logo) => dispatch(logoActions.setLogo(logo)),
    setMenuInfoFromResponse: (menuDetailResponse) => {
        dispatch(
            menuActions.setCategories(
                menuDetailResponse.categories.map(
                    (category) =>
                        new Category(
                            category.id,
                            category.sorting,
                            category.name,
                            category.hasOwnProperty("meta") &&
                                category.meta.hasOwnProperty("media")
                                ? category.meta.media
                                : null,
                            category.description,
                        )
                )
            )
        );
        dispatch(
            menuActions.setItems(
                menuDetailResponse.items
                    .filter((item) => !item.suspended)
                    .map(
                        (item) =>
                            new MenuItem(
                                item.id,
                                item.category,
                                item.name,
                                item.description,
                                item.price,
                                item.vat,
                                item.rules,
                                item.compositions
                                    .map((composition) => ({
                                        ...composition,
                                        ingredient:
                                            menuDetailResponse.ingredients.find(
                                                (i) =>
                                                    i.id ===
                                                    composition.ingredient
                                            ),
                                    }))
                                    .filter(item => !item.ingredient.suspended)
                                    .sort((a, b) => {
                                        const nameA =
                                            a.ingredient.name.toUpperCase();
                                        const nameB =
                                            b.ingredient.name.toUpperCase();
                                        if (nameA < nameB) {
                                            return -1;
                                        }
                                        if (nameA > nameB) {
                                            return 1;
                                        }
                                        return 0;
                                    }),
                                item.hasOwnProperty("meta") &&
                                    item.meta.hasOwnProperty("media")
                                    ? item.meta.media
                                    : null
                            )
                    )
                    .sort((a, b) => {
                        const nameA = a?.name?.toUpperCase();
                        const nameB = b?.name?.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    })
            )
        );
    },
    openSummary: () => dispatch(openSummaryActions.setTrue()),
});

const mapLocalStateToProps = (state) => ({
    posId: state.common.pos,
    menuId: state.common.menu,
    orderItems: state.order.items,
});

const mapLocalDispatchToProps = (dispatch) => ({
    setSiteInfoFromResponse: (infoResponse) => {
        dispatch(commonActions.setPosId(infoResponse.pointOfSaleId));
        dispatch(commonActions.setMenuId(infoResponse.menuId));
        dispatch(commonActions.setAvailablePaymetMethods(infoResponse.availablePaymentMethods));
        dispatch(commonActions.setOrderingModes(infoResponse.orderingModes));
        dispatch(
            commonActions.setMinOrderValue(
                parseFloat(infoResponse.minOrderValue)
            )
        );
        dispatch(
            commonActions.setDeliveryTimeRange(
                infoResponse.deliveryTimeRange
                    ? parseInt(infoResponse.deliveryTimeRange)
                    : null
            )
        );
        if (infoResponse.paypal) {
            dispatch(
                commonActions.setPayPal(
                    infoResponse.paypal.clientId,
                    infoResponse.paypal.checkout
                )
            );
        } else {
            dispatch(commonActions.unsetPayPal());
        }
        if (infoResponse.nexi) {
            dispatch(
                commonActions.setNexi(
                    infoResponse.nexi.alias,
                    infoResponse.nexi.url,
                    infoResponse.nexi.env
                )
            );
        } else {
            dispatch(commonActions.unsetNexi());
        }
    },
    removeItem: ({ id }) => dispatch(orderActions.removeItem(id)),
});

export default compose(
    connect(mapLocalStateToProps, mapLocalDispatchToProps, null, {
        context: LocalContext,
    }),
    connect(mapInMemoryStateToProps, mapInMemoryDispatchToProps, null, {
        context: InMemoryContext,
    }),
    withStyles(styles)
)(App);
