export const ReservedDeliveryTimeFilters = {
    SET: 'IN_MEMORY.RESERVED_DELIVERY_TIME.SET',
    CLEAN: 'IN_MEMORY.RESERVED_DELIVERY_TIME.CLEAN',
};

export const set = data => ({
    type: ReservedDeliveryTimeFilters.SET,
    data
});

export const clean = () => ({
    type: ReservedDeliveryTimeFilters.CLEAN
});
