import {OrderConfirmedFilters} from '../../actions/inMemory/orderConfirmed';

export default (state = false, action) => {
    const { type, ...data} = action;

    switch (type) {
        case OrderConfirmedFilters.BOOLEAN:
            return data.bool;
        default:
            return state
    }
}
