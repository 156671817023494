import React from "react";
import { combineReducers, compose, createStore } from 'redux';
import persistState from 'redux-localstorage';

// Local
import localOrder from './reducers/local/order';
import localOauth from './reducers/local/oauth';
import localCommon from './reducers/local/common';
import localRegistration from './reducers/local/registration';

// InMemory
import inMemoryAddress from './reducers/inMemory/address';
import inMemoryConfirmation from './reducers/inMemory/confirmation';
import inMemoryCustomer from './reducers/inMemory/customer';
import inMemoryFlashMessages from './reducers/inMemory/flashMessages';
import inMemoryLayout from './reducers/inMemory/layout';
import inMemoryMenu from './reducers/inMemory/menu';
import inMemoryOpenSummary from './reducers/inMemory/openSummary';
import inMemoryOrder from './reducers/inMemory/order';
import inMemoryOrderConfirmed from './reducers/inMemory/orderConfirmed';
import inMemoryPayment from './reducers/inMemory/payment';
import inMemoryPos from './reducers/inMemory/pos';
import inMemoryReservedDeliveryTime from './reducers/inMemory/reservedDeliveryTime';
import inMemoeryReservedTakeawayTime from './reducers/inMemory/reservedTakeawayTime';
import inMemoryStyle from './reducers/inMemory/style';
import inMemoryLogo from './reducers/inMemory/logo';
import inMemoryUser from './reducers/inMemory/user';
import inMemoryWorkshift from './reducers/inMemory/workshift';

const LocalContext = React.createContext('local');
const InMemoryContext = React.createContext('inMemory');

const preloadedLocalState = {
    oauth: null,
    registration: {
        user: null,
        wait: false
    },
    common: {
        pos: null,
        minOrderValue: 0,
        deliveryTimeRange: 0,
        menu: null,
        customerAddress: null,
        address: null,
        workshift: null,
        paypal: null,
        nexi: null,
    },
    order: {
        id: null,
        items: [],
        note: null,
        deliveryFee: 0
    }
};

const preloadedInMemoryState = {
    confirmation: {
        email: false,
    },
    pos: null,
    layout: {},
    style: 'classic',
    logo: null,
    menu: {
        categories: [],
        items: [],
        categorySelected: null,
        searchTerm: "",
    },
    payment: {
        method: 'undefined',
        status: 'waiting'
    },
    user: null,
    customer: null,
    address: null,
    workshift: null,
    reservedDeliveryTime: null,
    reservedTakeawayTime: null,
    orderConfirmed: false,
    openSummary: false,
    flashMessages: [],
    order: {
        inSync: false
    }
};

const localReducers = combineReducers({
    oauth: localOauth,
    registration: localRegistration,
    common: localCommon,
    order: localOrder,
});

const inMemoryReducers = combineReducers({
    confirmation: inMemoryConfirmation,
    pos: inMemoryPos,
    layout: inMemoryLayout,
    menu: inMemoryMenu,
    order: inMemoryOrder,
    user: inMemoryUser,
    style: inMemoryStyle,
    logo: inMemoryLogo,
    customer: inMemoryCustomer,
    address: inMemoryAddress,
    workshift: inMemoryWorkshift,
    reservedDeliveryTime: inMemoryReservedDeliveryTime,
    reservedTakeawayTime: inMemoeryReservedTakeawayTime,
    orderConfirmed: inMemoryOrderConfirmed,
    payment: inMemoryPayment,
    openSummary: inMemoryOpenSummary,
    flashMessages: inMemoryFlashMessages
});

const localRootReducer = (state, action) => {
    if (action.type === 'APP:RESET') {
        state = Object.assign({}, preloadedLocalState);
    }
    return localReducers(state, action);
}

const inMemoryRootReducer = (state, action) => {
    if (action.type === 'APP:RESET') {
        state = Object.assign({}, preloadedInMemoryState);
    }
    return inMemoryReducers(state, action);
}

const localStore = createStore(localRootReducer, preloadedLocalState, compose(persistState()));
const inMemoryStore = createStore(inMemoryRootReducer, preloadedInMemoryState);

export {
    LocalContext,
    localStore,

    InMemoryContext,
    inMemoryStore,
}
