import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { InMemoryContext, LocalContext } from "../../redux/store";
import { Box, Hidden, withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { compose } from "redux";
import LazyLoad from "../LazyLoad";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { ChooseLanguage } from "../ChooseLanguage";

const OrderMenuAmount = React.lazy(() => import("../OrderMenuAmount"));

class Navbar extends React.Component {
    render() {
        const { pos, location, classes, layout, logo } = this.props;

        if (!pos) return null;

        return (
            <NavbarWrapper
                py={logo ? 1 : 2}
                mb={3}
                boxShadow={2}
                bgcolor="background.paper"
                style={layout.header || null}
            >
                <Container>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Box flexGrow={1} display="flex" alignItems="center" justifyContent="space-between">
                            <NavLink to="/" style={{ color: '#3B9684', textDecoration: "none" }}>
                                {logo ? (
                                    <img
                                        src={`${process.env.REACT_APP_PUBLIC_CDN_BASE_URL}/${logo}`}
                                        style={{ height: 70, marginRight: 10 }}
                                        alt={pos.name}
                                    />
                                ) : (
                                    <Typography variant="h5">
                                        <strong>{pos.name}</strong>
                                    </Typography>
                                )}
                            </NavLink>
                            <ChooseLanguage />
                        </Box>

                        {location && location.pathname === "/" && (
                            <Hidden mdUp>
                                <Box textAlign="center" ml={1}>
                                    <LazyLoad
                                        component={OrderMenuAmount}
                                        chipStyle
                                        onClickChip={this.props.onClickCart}
                                    />
                                </Box>
                            </Hidden>
                        )}
                    </Box>
                </Container>
            </NavbarWrapper>
        );
    }
}

const NavbarWrapper = styled(Box)`
    position: sticky;
    top: 0;
    z-index: 2;
`;

const mapInMemoryStateToProps = (state) => ({
    pos: state.pos,
    layout: state.layout,
    logo: state.logo,
});

const mapLocalStateToProps = (state) => ({
    totalItems: state.order.items.length,
});

Navbar.propTypes = {
    onClickCart: PropTypes.func,
};

Navbar.defaultProps = {
    onClickCart: () => {},
};

export default compose(
    connect(mapInMemoryStateToProps, null, null, { context: InMemoryContext }),
    connect(mapLocalStateToProps, null, null, { context: LocalContext }),
    withRouter
)(Navbar);
