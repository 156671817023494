import {PaymentFilters} from '../../actions/inMemory/payment';

export default (state = null, action) => {
    const { type, ...data} = action;

    switch (type) {
        case PaymentFilters.SET_METHOD:
            return { ...state, method: data.method }
        case PaymentFilters.SET_STATUS:
            return { ...state, status: data.status }
        case PaymentFilters.RESET:
            return {
                method: 'undefined',
                status: 'waiting'
            }
        default:
            return state
    }
}
