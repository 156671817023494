export const OrderConfirmedFilters = {
    BOOLEAN: 'IN_MEMORY.ORDER_CONFIRMED.BOOLEAN',
};

export const setTrue = () => ({
    type: OrderConfirmedFilters.BOOLEAN,
    bool: true
});

export const setFalse = () => ({
    type: OrderConfirmedFilters.BOOLEAN,
    bool: false
});
