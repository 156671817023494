import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";

const styles = {
    root: {
        flexGrow: 1,
        minHeight: "100vh",
    },
};

class StatusDisabled extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <Grid
                container
                spacing={2}
                direction="column"
                alignItems="center"
                justifyContent="center"
                className={classes.root}
            >
                <Grid item>
                    <Box p={2}>
                        <Typography variant="h2" gutterBottom>
                            Opss! Sito offline
                        </Typography>
                        <Typography gutterBottom>
                            Siamo spiacenti di informarti che il sito per la
                            presa degli ordini è temporaneamente disabilitato
                        </Typography>
                        <Typography>
                            Non sappiamo quando sarà di nuovo accessibile ma ci
                            impegniamo per far si che sia il prima possibile
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(StatusDisabled);
