import { ReservedTakeawayTimeFilters } from '../../actions/inMemory/reservedTakeawayTime';

export default (state = null, action) => {
    const { type, ...data } = action;

    switch (type) {
        case ReservedTakeawayTimeFilters.SET:
            return { ...data.data }
        case ReservedTakeawayTimeFilters.CLEAN:
            return null
        default:
            return state
    }
}
