export const WorkshiftFilters = {
    SET: 'IN_MEMORY.WORKSHIFT.SET',
    CLEAN: 'IN_MEMORY.WORKSHIFT.CLEAN',
};

export const setWorkshift = workshift => ({
    type: WorkshiftFilters.SET,
    workshift
});

export const clean = () => ({
    type: WorkshiftFilters.CLEAN
});