import {ConfirmationFilters} from '../../actions/inMemory/confirmation';

export default (state = null, action) => {
    const { type, ...data} = action;

    switch (type) {
        case ConfirmationFilters.UPDATE_EMAIL_FLAG:
            return { ...state, email: data.bool };
        default:
            return state
    }
}
