import React from 'react';
import ReactDOM from 'react-dom';
import Init from './Init';
import * as serviceWorker from './serviceWorker';
import 'typeface-roboto';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { Provider } from 'react-redux';
import { InMemoryContext, inMemoryStore, LocalContext, localStore } from './redux/store';
import i18n from "./i18n";
import './index.css';

const detectedLng = i18n.language;
if (detectedLng && detectedLng !== 'cimode') {
    window.document.cookie = `i18next=${detectedLng};path=/;max-age=31536000`;
}

ReactDOM.render(
    <Provider store={localStore} context={LocalContext}>
        <Provider store={inMemoryStore} context={InMemoryContext}>
            <Init />
        </Provider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
