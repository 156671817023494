export const CustomerFilters = {
    SET_INFO: 'IN_MEMORY.CUSTOMER.SET_INFO',
    UPDATE_FIELD: 'IN_MEMORY.CUSTOMER.UPDATE_FIELD',
    CLEAN: 'IN_MEMORY.CUSTOMER.CLEAN',
};

export const setInfo = info => ({
    type: CustomerFilters.SET_INFO,
    info
});

export const updateField = (field, value) => ({
    type: CustomerFilters.UPDATE_FIELD,
    field, value
});

export const clean = () => ({
    type: CustomerFilters.CLEAN
});
