export const UserFilters = {
    SET_INFO: 'IN_MEMORY.USER.SET_INFO',
    UPDATE_FIELD: 'IN_MEMORY.USER.UPDATE_FIELD',
    VALIDATE_MOBILE_PHONE_NUMBER: 'IN_MEMORY.USER.VALIDATE_MOBILE_PHONE_NUMBER',
    CLEAN: 'IN_MEMORY.USER.CLEAN',
};

export const setInfo = info => ({
    type: UserFilters.SET_INFO,
    info
});

export const updateField = (field, value) => ({
    type: UserFilters.UPDATE_FIELD,
    field, value
});

export const validateMobilePhoneNumber = () => ({
    type: UserFilters.VALIDATE_MOBILE_PHONE_NUMBER
});

export const clean = () => ({
    type: UserFilters.CLEAN
});
