import {CommonFilters} from '../../actions/local/common';

export default (state = null, action) => {
    const { type, ...data} = action;

    switch (type) {
        case CommonFilters.SET_POS_ID:
            return { ...state, pos: data.id };
        case CommonFilters.UNSET_POS_ID:
            return { ...state, pos: null };

        case CommonFilters.SET_MENU_ID:
            return { ...state, menu: data.id };
        case CommonFilters.UNSET_MENU_ID:
            return { ...state, menu: null };

        case CommonFilters.SET_PAYPAL:
            return { ...state, paypal: data };
        case CommonFilters.UNSET_PAYPAL:
            return { ...state, paypal: null };

        case CommonFilters.SET_NEXI:
            return { ...state, nexi: data };
        case CommonFilters.UNSET_NEXI:
            return { ...state, nexi: null };

        case CommonFilters.SET_ADDRESS_ID:
            return { ...state, address: data.id };
        case CommonFilters.UNSET_ADDRESS_ID:
            return { ...state, address: null };

        case CommonFilters.SET_CUSTOMER_ADDRESS_ID:
            return { ...state, customerAddress: data.id };
        case CommonFilters.UNSET_CUSTOMER_ADDRESS_ID:
            return { ...state, customerAddress: null };

        case CommonFilters.SET_WORKSHIFT_ID:
            return { ...state, workshift: data.id };
        case CommonFilters.UNSET_WORKSHIFT_ID:
            return { ...state, workshift: null };
        case CommonFilters.SET_MIN_ORDER_VALUE:
            return { ...state, minOrderValue: data.value };
        case CommonFilters.SET_AVAILABLE_PAYMENT_METHODS:
            return { ...state, availablePaymentMethods: data.methods };
        case CommonFilters.SET_ORDERING_MODES:
            return { ...state, orderingModes: data.orderingModes };
        case CommonFilters.SET_DELIVERY_TIME_RANGE:
            return { ...state, deliveryTimeRange: data.value };

        default:
            return state
    }
}
