export const AddressFilters = {
    SET: 'IN_MEMORY.ADDRESS.SET',
    CLEAN: 'IN_MEMORY.ADDRESS.CLEAN',
};

export const setAddress = address => ({
    type: AddressFilters.SET,
    address
});

export const clean = () => ({
    type: AddressFilters.CLEAN
});