import {WorkshiftFilters} from '../../actions/inMemory/workshift';

export default (state = null, action) => {
    const { type, ...data} = action;

    switch (type) {
        case WorkshiftFilters.SET:
            return { ...data.workshift };
        case WorkshiftFilters.CLEAN:
            return null;
        default:
            return state
    }
}
