import checkoutIT from './checkout.it.json';
import checkoutEN from './checkout.en.json';
import checkoutES from './checkout.es.json';

import translationIT from './translation.it.json';
import translationEN from './translation.en.json';
import translationES from './translation.es.json';

import forgotPasswordIT from './forgotPassword.it.json';
import forgotPasswordEN from './forgotPassword.en.json';
import forgotPasswordES from './forgotPassword.es.json';

import signinIT from './signin.it.json';
import signinEN from './signin.en.json';
import signinES from './signin.es.json';

import signupIT from './signup.it.json';
import signupEN from './signup.en.json';
import signupES from './signup.es.json';

import stepbarIT from './stepbar.it.json';
import stepbarEN from './stepbar.en.json';
import stepbarES from './stepbar.es.json';

import userbarIT from './userbar.it.json';
import userbarEN from './userbar.en.json';
import userbarES from './userbar.es.json';

import violationsIT from './violations.it.json';
import violationsEN from './violations.en.json';
import violationsES from './violations.es.json';

import finalizedIT from './finalized.it.json';
import finalizedEN from './finalized.en.json';
import finalizedES from './finalized.es.json';

import userIT from './user.it.json';
import userEN from './user.en.json';
import userES from './user.es.json';

import phoneNumberConfirmationIT from './phoneNumberConfirmation.it.json';
import phoneNumberConfirmationEN from './phoneNumberConfirmation.en.json';
import phoneNumberConfirmationES from './phoneNumberConfirmation.es.json';

export default {
    it: {
        translation: translationIT,
        signup: signupIT,
        'forgot-password': forgotPasswordIT,
        signin: signinIT,
        stepbar: stepbarIT,
        checkout: checkoutIT,
        userbar: userbarIT,
        finalized: finalizedIT,
        user: userIT,
        violations: violationsIT,
        'phone-number-confirmation': phoneNumberConfirmationIT
    },
    en: {
        translation: translationEN,
        signup: signupEN,
        'forgot-password': forgotPasswordEN,
        signin: signinEN,
        stepbar: stepbarEN,
        checkout: checkoutEN,
        userbar: userbarEN,
        finalized: finalizedEN,
        user: userEN,
        violations: violationsEN,
        'phone-number-confirmation': phoneNumberConfirmationEN
    },
    es: {
        translation: translationES,
        signup: signupES,
        'forgot-password': forgotPasswordES,
        signin: signinES,
        stepbar: stepbarES,
        checkout: checkoutES,
        userbar: userbarES,
        finalized: finalizedES,
        user: userES,
        violations: violationsES,
        'phone-number-confirmation': phoneNumberConfirmationES
    }
}