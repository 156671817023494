export const ReservedTakeawayTimeFilters = {
    SET: 'IN_MEMORY.RESERVED_TAKEAWAY_TIME.SET',
    CLEAN: 'IN_MEMORY.RESERVED_TAKEAWAY_TIME.CLEAN',
};

export const set = data => ({
    type: ReservedTakeawayTimeFilters.SET,
    data
});

export const clean = () => ({
    type: ReservedTakeawayTimeFilters.CLEAN
});
