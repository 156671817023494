export const RegistrationFilters = {
    SET_USER: 'LOCAL.REGISTRATION.SET_USER',
    UNSET_USER: 'LOCAL.REGISTRATION.UNSET_USER',
    UPDATE_WAIT_FLAG: 'LOCAL.REGISTRATION.UPDATE_WAIT_FLAG',
};

export const setUserId = id => ({
    type: RegistrationFilters.SET_USER,
    id
});

export const unsetUserId = () => ({
    type: RegistrationFilters.UNSET_USER
});

export const updateWaitFlag = bool => ({
    type: RegistrationFilters.UPDATE_WAIT_FLAG,
    bool
});