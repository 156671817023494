import { OrderFilters } from '../../actions/local/order';
import { v4 as uuid4 } from 'uuid';

export default (state = null, action) => {
    const { type, ...data} = action;

    switch (type) {
        case OrderFilters.SET_ORDER_ID:
            return { ...state, id: data.id };

        case OrderFilters.CLEAN:
            return {
                id: null,
                items: [],
                note: null,
                deliveryFee: 0
            };

        case OrderFilters.SET_ITEM:
            const reformatItems = (items, newItem) => {
                const { id, menuItemId, quantity, variants, note } = newItem;

                const existingItemIdx = items.findIndex(item => {
                    if (item.menuItemId !== menuItemId) {
                        return false;
                    }
                    if (item.note !== note) {
                        return false;
                    }
                    if (variants.length !== item.variants.length) {
                        return false;
                    }
                    return (
                        item.variants.filter(
                            ({ ingredientId, ruleId }) =>
                                variants.find(
                                    ({ ingredientId: ingredientId1, ruleId: ruleId1 }) =>
                                        ingredientId === ingredientId1 && ruleId === ruleId1
                                ) !== undefined
                        ).length === variants.length
                    );
                });

                if (!id) {
                    if (existingItemIdx === -1) {
                        items.push({ id: uuid4(), menuItemId, quantity, variants, note });
                    } else {
                        items[existingItemIdx].quantity += quantity;
                    }
                } else {
                    if (existingItemIdx === -1) {
                        const currentItemIdx = items.findIndex(item => item.id === id);
                        items[currentItemIdx] = { id, menuItemId, quantity, variants, note };
                    } else {
                        if (items[existingItemIdx].id !== id) {
                            items = items.filter(item => item.id !== items[existingItemIdx].id);
                        }
                        items[existingItemIdx].quantity = quantity;
                    }
                }

                return [...items];
            }

            return {
                ...state,
                items: reformatItems(state.items, data)
            };

        case OrderFilters.SET_DELIVERY_FEE:
            return {
                ...state,
                deliveryFee: data.deliveryFee || 0
            };

        case OrderFilters.REMOVE_ITEM:
            return {
                ...state,
                items: state.items.filter(item => item.id !== data.id)
            };

        case OrderFilters.UPDATE_NOTE:
            return { ...state, note: data.note };

        default:
            return state
    }
}
