import {StyleFilters} from '../../actions/inMemory/style';

export default (state = null, action) => {
    const { type, ...data} = action;

    switch (type) {
        case StyleFilters.SET_STYLE:
            return data.style;

        default:
            return state
    }
}
