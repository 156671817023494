import { MenuFilters } from '../../actions/inMemory/menu';

export default (state = null, action) => {
    const { type, ...data } = action;

    switch (type) {
        case MenuFilters.SET_CATEGORIES:
            const categories = data.categories
                .reduce((categories, category) => {
                    return categories.find(c => c.id === category.id)
                        ? categories
                        : categories.concat(category)
                }, [])
                .sort((a, b) => a.sorting - b.sorting);
            return {
                ...state,
                categories
            };
        case MenuFilters.SELECT_CATEGORY:
            return {
                ...state,
                categorySelected: data.id
            };
        case MenuFilters.UNSELECT_CATEGORY:
            return {
                ...state,
                categorySelected: null
            };
        case MenuFilters.SET_ITEMS:
            return {
                ...state,
                items: data.items
            };
        case MenuFilters.SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: data.term
            };
        default:
            return state
    }
}
