import {LogoFilters} from '../../actions/inMemory/logo';

export default (state = null, action) => {
    const { type, ...data} = action;

    switch (type) {
        case LogoFilters.SET_LOGO:
            return data.logo;

        default:
            return state
    }
}
