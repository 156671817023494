import {OAuthFilters} from '../../actions/local/oauth';
import dayjs from "dayjs";

const EXPIRE_SAFE_SECONDS = 300;

export default (state = null, action) => {
    const { type, ...data } = action;

    switch (type) {
        case OAuthFilters.OAUTH_SAVE:
            return {
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
                expiresAt: dayjs().add(data.expiresIn - EXPIRE_SAFE_SECONDS, 'second').format(),
                scope: data.scope
            };
        case OAuthFilters.OAUTH_REFRESH:
            return {
                ...state,
                accessToken: data.accessToken,
                expiresAt: dayjs().add(data.expiresIn - EXPIRE_SAFE_SECONDS, 'second').format()
            };
        case OAuthFilters.OAUTH_CLEAN:
            return null;
        default:
            return state
    }
}
