import {RegistrationFilters} from '../../actions/local/registration';

export default (state = null, action) => {
    const { type, ...data} = action;

    switch (type) {
        case RegistrationFilters.SET_USER:
            return { ...state, user: data.id };
        case RegistrationFilters.UNSET_USER:
            return { ...state, user: null };
        case RegistrationFilters.UPDATE_WAIT_FLAG:
            return { ...state, wait: data.bool };
        default:
            return state
    }
}
