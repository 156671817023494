import {AddressFilters} from '../../actions/inMemory/address';

export default (state = null, action) => {
    const { type, ...data} = action;

    switch (type) {
        case AddressFilters.SET:
            return { ...data.address };
        case AddressFilters.CLEAN:
            return null;
        default:
            return state
    }
}
