import {LayoutFilters} from '../../actions/inMemory/layout';

export default (state = null, action) => {
    const { type, ...data} = action;

    switch (type) {
        case LayoutFilters.SET_LAYOUT_OPTIONS:
            return { ...data.options };

        default:
            return state
    }
}
