import React from "react";

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

export const loadGoogleMaps = () => {
    if (!document.querySelector('#google-maps')) {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=geometry,drawing,places`,
            document.querySelector('head'),
            'google-maps',
        );
    }
};