export const PaymentFilters = {
    SET_METHOD: 'IN_MEMORY.PAYMENT.SET_METHOD',
    SET_STATUS: 'IN_MEMORY.PAYMENT.SET_STATUS',
    RESET: 'IN_MEMORY.PAYMENT.RESET',
};

export const setMethod = method => ({
    type: PaymentFilters.SET_METHOD,
    method
});

export const setStatus = status => ({
    type: PaymentFilters.SET_STATUS,
    status
});

export const reset = () => ({
    type: PaymentFilters.RESET
});
