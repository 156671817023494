import i18n from "i18next";
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import i18nResources from './resources';

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['cookie', 'localStorage', 'navigator'],
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
        },
        resources: i18nResources,
        fallbackLng: 'en',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;