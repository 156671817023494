import React from "react";
import * as clientAuth from '../client/auth';
import * as oauthActions from '../redux/actions/local/oauth';
import {connect} from "react-redux";
import {LocalContext} from "../redux/store";
import dayjs from "dayjs";
import LoaderFullPage from "./LoaderFullPage";
import {OAuthScopes} from "../redux/actions/local/oauth";
import StatusDisabled from "../view/StatusDisabled";

class DomainAuthenticator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
        };
        this.authorize = this.authorize.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.props.needRefresh
            ? this.refresh()
            : this.authorize()
        ;
    }

    authorize() {
        clientAuth.authorize()
            .then(({ data: authResponse }) => {
                this.props.saveOauthTokenResponse(authResponse);
            })
            .catch(e => this.setState({ error: true }, () => console.error(e)))
    }

    refresh() {
        clientAuth.refresh()
            .then(({ data: refreshResponse }) => {
                this.props.refreshOauthToken(refreshResponse);
            })
            .catch(() => {
                this.props.cleanOauthToken();
                this.authorize();
            })
    }

    render() {
        const { error } = this.state;

        if (error) {
            return <StatusDisabled />;
        }

        return <LoaderFullPage/>
    }
}

const mapStateToProps = state => ({
    needRefresh: state.oauth !== null && dayjs().isAfter(dayjs(state.oauth.expiresAt))
});

const mapDispatchToProps = dispatch => ({
    saveOauthTokenResponse: authResponse => dispatch(oauthActions.save(
        authResponse.access_token,
        authResponse.refresh_token,
        authResponse.expires_in,
        OAuthScopes.PUBLIC
    )),
    refreshOauthToken: refreshResponse => dispatch(oauthActions.refresh(
        refreshResponse.access_token,
        refreshResponse.expires_in
    )),
    cleanOauthToken: () => dispatch(oauthActions.clean()),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { context: LocalContext })(DomainAuthenticator);