import {CustomerFilters} from '../../actions/inMemory/customer';

export default (state = null, action) => {
    const { type, ...data} = action;

    switch (type) {
        case CustomerFilters.SET_INFO:
            return { ...state, ...data.info }
        case CustomerFilters.UPDATE_FIELD:
            return { ...state, [data.field]: data.value }
        case CustomerFilters.CLEAN:
            return null
        default:
            return state
    }
}
