import Axios from 'axios';
import { localStore, inMemoryStore } from '../redux/store';
import qs from 'qs';
import i18n from '../i18n';

const localeMapping = {
    es: 'es_ES',
    en: 'en_GB',
    it: 'it_IT',
    ar: 'ar_AR',
}

class ApiClient {
    _cancelToken;

    constructor() {
        this._cancelToken = null;
    }

    _getHeaders(skipAuth = false) {
        console.log('i18n.language', i18n.language);

        let headers = {};
        headers['Content-Type'] = 'application/json';
        headers['X-I18n-Locale'] = i18n.language
            ? localeMapping[i18n.language.substring(0, 2)] || localeMapping.en
            : localeMapping.en;

        const auth = localStore.getState().oauth;

        if (null !== auth && !skipAuth) {
            headers['Authorization'] = `Bearer ${auth.accessToken}`;
        }

        return headers;
    }

    abort() {
        if (null !== this._cancelToken) {
            this._cancelToken.cancel('Operation aborted by the user');
            this._cancelToken = null;
        }
    }

    _getCancelToken() {
        if (null === this._cancelToken) {
            this._cancelToken = Axios.CancelToken.source();
        }
        return this._cancelToken.token;
    }

    me() {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_API}/me`, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    addressCreateFromPlaceId(placeId) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/address/create/place-id`, { placeId }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    customerAddressDetail(id = undefined) {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_API}/customer/address/${id || localStore.getState().common.customerAddress || '_'}/detail`, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    customerOrderDetail(orderId) {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_API}/order/${orderId}/detail`, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    customerOrders(page = 1, params = {}) {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_API}/me/order/list`, {
            params: {
                ...params,
                page,
                status: 'valid'
            },
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    deliveryTimes(workshiftId, addressId) {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_API}/workshift/${workshiftId}/address/${addressId}/delivery/times`, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    takeawayTimes(workshiftId) {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_API}/workshift/${workshiftId}/take-away/times`, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    meCustomerUpdatePassword(data) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/me/update/password`, data, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    meCustomerDetail() {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_API}/me/customer/detail/pos/:id`.replace(':id', localStore.getState().common.pos), {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    meCustomerAddressList() {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_API}/me/customer/addresses/pos/:id`.replace(':id', localStore.getState().common.pos), {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    meCustomerCreate() {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/me/customer/create`, { pointOfSale: localStore.getState().common.pos }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    meUpdate(data) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/me/update`, data, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    meCustomerOwnership() {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/me/customer/ownership/pos`, { pointOfSale: localStore.getState().common.pos }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    updateMobilePhoneNumber(mobilePhoneNumber) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/me/update/mobile-phone-number`, { mobilePhoneNumber }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    confirmationMobilePhoneNumberRequest() {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/me/verification/mobile/request`, null, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    confirmationMobilePhoneNumberValidate(code) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/me/verification/mobile/validate`, { code }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    createManagedOrder() {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/me/order/create/managed`, {
            pointOfSale: localStore.getState().common.pos,
            customerAddress: localStore.getState().common.customerAddress,
            items: localStore.getState().order.items.reduce((items, item) => {
                for (let i = 0; i < item.quantity; i++) {
                    items.push({
                        menuItem: item.menuItemId,
                        note: item.note,
                        variants: item.variants.map(variant => ({
                            ingredient: variant.ingredientId,
                            rule: variant.ruleId,
                            actionType: variant.action
                        }))
                    });
                }
                return items;
            }, []),
            note: localStore.getState().order.note
        }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    createManagedOrderTakeaway() {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/me/order/create/take-away`, {
            pointOfSale: localStore.getState().common.pos,
            items: localStore.getState().order.items.reduce((items, item) => {
                for (let i = 0; i < item.quantity; i++) {
                    items.push({
                        menuItem: item.menuItemId,
                        note: item.note,
                        variants: item.variants.map(variant => ({
                            ingredient: variant.ingredientId,
                            rule: variant.ruleId,
                            actionType: variant.action
                        }))
                    });
                }
                return items;
            }, []),
            note: localStore.getState().order.note
        }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    distanceChecker(addressId) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/pos/${localStore.getState().common.pos}/distance/check`, {
            address: addressId,
        }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    orderInit(type) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/pos/${localStore.getState().common.pos}/order/init`, {
            type
        }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    orderDetail(id = undefined) {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_API}/order/${id || localStore.getState().order.id || '_'}/detail`, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    applyDeliveryFee(id = undefined) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/order/${id || localStore.getState().order.id || '_'}/delivery/fee/apply`, {}, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    updateOrderCustomerAddress() {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/order/${localStore.getState().order.id}/update/customer-address`, {
            customerAddress: localStore.getState().common.customerAddress,
        }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    unsetOrderCustomerAddress() {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/order/${localStore.getState().order.id}/unset/customer-address`, {}, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    updateOrderNote() {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/order/${localStore.getState().order.id}/update/note`, {
            note: localStore.getState().order.note
        }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    updateOrderPayment(method, status = 'waiting') {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/order/${localStore.getState().order.id}/update/payment`, {
            method,
            status
        }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    updateOrderItems() {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/order/${localStore.getState().order.id}/update/menu/items`, {
            items: localStore.getState().order.items.reduce((items, item) => {
                for (let i = 0; i < item.quantity; i++) {
                    items.push({
                        menuItem: item.menuItemId,
                        note: item.note,
                        variants: item.variants.map(variant => ({
                            ingredient: variant.ingredientId,
                            rule: variant.ruleId,
                            actionType: variant.action
                        }))
                    });
                }
                return items;
            }, [])
        }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    deliveryCreate(orderId) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/delivery/create`, {
            reservedDeliveryTime: orderId
        }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    takeawayCreate(orderId) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/take-away/create`, {
            reservedTakeAwayTime: orderId
        }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    siteInfo() {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_SITE_API}/info`, {
            responseType: 'json',
            cancelToken: this._getCancelToken()
        });
    }

    posDetail() {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_API}/pos/:id/detail`.replace(':id', localStore.getState().common.pos), {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    menuDetail() {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_API}/menu/:id/detail`.replace(':id', localStore.getState().common.menu), {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    register(data) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_SITE_API}/registration/register`, data, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    requestResetPassword(email) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_SITE_API}/reset-password/request`, { email }, {
            responseType: 'json',
            headers: this._getHeaders(true),
            cancelToken: this._getCancelToken()
        });
    }

    customerAddressCreate(data, main = true) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/customer/${inMemoryStore.getState().customer.id}/address/create`, { ...data, main }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    customerAddressDelete(id) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/customer/address/${id}/delete`, null, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    reserveDeliveryTime(workshiftId, orderId, deliveryTime) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/workshift/${workshiftId}/delivery/time/reserve`, {
            order: orderId,
            deliveryTime: deliveryTime.format('YYYY-MM-DD HH:mm')
        }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    reservedDeliveryTime(orderId) {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_API}/order/${orderId}/reserved/delivery/time`, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    removeReservedDeliveryTime(orderId) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/order/${orderId}/reserved/delivery/time/remove`, null, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    reserveTakeawayTime(workshiftId, orderId, takeawayTime) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/workshift/${workshiftId}/take-away/time/reserve`, {
            order: orderId,
            takeAwayTime: takeawayTime.format('YYYY-MM-DD HH:mm')
        }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    reservedTakeawayTime(orderId) {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_API}/order/${orderId}/reserved/take-away/time`, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    removeReservedTakeawayTime(orderId) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/order/${orderId}/reserved/take-away/time/remove`, null, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    paypalTxnSave(paymentId) {
        return Axios.post(`${process.env.REACT_APP_BASE_URL_API}/order/${localStore.getState().order.id}/payment/paypal/save`, {
            paymentId
        }, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    nexiTxnParams() {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_API}/order/${localStore.getState().order.id}/payment/nexi/params`, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    workshiftDetail(id) {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_API}/workshift/${id}/detail`, {
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    workshiftList(from, to, page = 1) {
        return Axios.get(`${process.env.REACT_APP_BASE_URL_API}/pos/${localStore.getState().common.pos}/workshifts`, {
            params: {
                page,
                startAtLocalizedDateOrEndAtLocalizedDateIntersectRange: {
                    from: from.format('YYYY-MM-DD'),
                    to: to.format('YYYY-MM-DD'),
                },
                onlyEnabled: true,
                withAtLeastOneEnabledSlot: true,
                type: 'internal'
            },
            paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
            responseType: 'json',
            headers: this._getHeaders(),
            cancelToken: this._getCancelToken()
        });
    }

    async allWorkshifts(from, to) {
        let page = 1;
        let workshifts = [];
        let hasNext = true;

        while (hasNext) {
            const { data: response } = await this.workshiftList(from, to, page);
            hasNext = response.pagination.page < response.pagination.pageCount;
            workshifts = workshifts.concat(response.items);
            page++;
        }

        return workshifts.sort((a, b) => new Date(a.start) - new Date(b.start));
    }
}

export default ApiClient;