import React from "react";
import Loader from "react-loader-spinner";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

const styles = {
    root: {
        flexGrow: 1,
        minHeight: "100vh",
    },
};

function LoaderFullPage(props) {
    const { classes } = props;

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.root}
        >
            <Grid item>
                <Loader
                    type="ThreeDots"
                    color="#FFFFFF"
                    height={100}
                    width={100}
                />
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(LoaderFullPage);
