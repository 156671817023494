import {ReservedDeliveryTimeFilters} from '../../actions/inMemory/reservedDeliveryTime';

export default (state = null, action) => {
    const { type, ...data} = action;

    switch (type) {
        case ReservedDeliveryTimeFilters.SET:
            return { ...data.data }
        case ReservedDeliveryTimeFilters.CLEAN:
            return null
        default:
            return state
    }
}
