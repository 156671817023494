import {UserFilters} from '../../actions/inMemory/user';

export default (state = null, action) => {
    const { type, ...data} = action;

    switch (type) {
        case UserFilters.SET_INFO:
            return { ...state, ...data.info }
        case UserFilters.UPDATE_FIELD:
            return { ...state, [data.field]: data.value }
        case UserFilters.VALIDATE_MOBILE_PHONE_NUMBER:
            return { ...state, verification: { ...state.verification, mobilePhoneNumber: true }}
        case UserFilters.CLEAN:
            return null
        default:
            return state
    }
}
