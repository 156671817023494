import _ from "lodash";
import React from "react";
import styled from "styled-components";
import i18n from "../i18n";

const getCookie = (name) =>
    document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))?.at(2);

const LANGUAGES = ["en", "it", "es"];

export const ChooseLanguage = () => {
    const [language, setLanguage] = React.useState(
        getCookie("i18next").substring(0, 2) || "en"
    );

    React.useEffect(() => {
        const language = getCookie("i18next").substring(0, 2) || "en";
        setLanguage(language);
    }, []);

    return (
        <Details>
            <Summary>
                <LanguageFlag flag={language} />
            </Summary>
            <List>
                {LANGUAGES.filter((lan) => lan !== language).map((locale) => (
                    <li key={locale}>
                        <Anchor
                            href="#"
                            onClick={() => {
                                document.cookie = `i18next=${locale};path=/`;
                                i18n.changeLanguage(locale);
                                setLanguage(locale);
                            }}
                        >
                            <LanguageFlag flag={locale} />
                        </Anchor>
                    </li>
                ))}
            </List>
        </Details>
    );
};

const Anchor = styled.a`
  text-decoration: none;
  display: flex;
`;

const LanguageFlag = ({ flag }) => {
    let _flag = flag;
    switch (_flag) {
        case "en":
            _flag = "gb";
            break;
        case "it":
            _flag = "it";
            break;
        case "es":
            _flag = "es";
            break;
        default:
            _flag = "gb";
    }

    return (
        <Flag
            src={`https://flagsapi.com/${_flag.toUpperCase()}/flat/64.png`}
            alt={_flag}
        />
    );
};

const Details = styled.details`
  position: relative;
`;

const Summary = styled.summary`
  cursor: pointer;
  display: flex;

  &::-webkit-details-marker {
    display: none;
  }
`;

const List = styled.ul`
  background-color: #ffffff;
  border-radius: 0.25rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
`;

const Flag = styled.img`
  height: 2rem;
  padding: 0 0.25rem;
  width: 2rem;
`;
