export const OpenSummaryFilters = {
    BOOLEAN: 'IN_MEMORY.OPEN_SUMMARY.BOOLEAN',
};

export const setTrue = () => ({
    type: OpenSummaryFilters.BOOLEAN,
    bool: true
});

export const setFalse = () => ({
    type: OpenSummaryFilters.BOOLEAN,
    bool: false
});
