import React from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import dayjs from "dayjs";
import { connect, Provider } from "react-redux";
import { InMemoryContext, inMemoryStore, LocalContext } from "./redux/store";
import DomainAuthenticator from "./components/DomainAuthenticator";
import App from "./App";
import Pusher from "pusher-js";
import { PusherProvider } from "react-pusher-hoc";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import { compose } from "redux";
import { ErrorBoundary } from "react-error-boundary";
import { Box, Button, Typography } from "@material-ui/core";
import { Trans } from "react-i18next";

function ErrorFallback({ error, resetErrorBoundary }) {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh"
        >
            <Box textAlign="center" width={400}>
                <Typography variant="h3">
                    <Trans ns="translation" i18nKey="error_handler.title" />
                </Typography>
                <Typography variant="body1">
                    <Trans
                        ns="translation"
                        i18nKey="error_handler.description"
                    />
                </Typography>
                <Box mt={2} mb={3}>
                    <pre>{error.message}</pre>
                </Box>
                <Button onClick={resetErrorBoundary} variant="contained">
                    <Trans ns="translation" i18nKey="error_handler.button" />
                </Button>
            </Box>
        </Box>
    );
}

const theme = (options) =>
    createTheme({
        typography: {
            fontFamily: "Avenir Black, sans-serif",
        },
        palette: {
            background: {
                default: options.hasOwnProperty("backgroundColor")
                    ? options.backgroundColor || "#F6F1F1"
                    : "#F6F1F1",
            },
            text: {
                primary: options.hasOwnProperty("color")
                    ? options.color || "#2A2E34"
                    : "#2A2E34",
            },
        },
        components: {
            MuiTypography: {
              styleOverrides: {
                root: {
                  color: options.hasOwnProperty('typography') ? options.typography.primary : null,
                },
                /* Use the css method to directly target .MuiTypography-colorPrimary */
                '&.MuiTypography-colorPrimary': {
                  color: 'red', // Change color to red as an example
                },
              },
            },
          },
        overrides: {
            MuiAlert: {
                root: {
                    borderRadius: "0.5rem",
                },
                standardInfo: {
                    backgroundColor: "#D7EDE9 !important",
                    color: "#6E6D6D !important",
                },
                icon: {
                    color: '#3B9684 !important',
                },
            },
            MuiPaper: {
                rounded: {
                    borderRadius: "1rem",
                },
            },
            MuiCard: {
                root: options.hasOwnProperty("card")
                    ? options.card.content
                    : null,
            },
            MuiCardHeader: {
                title: options.hasOwnProperty("card")
                    ? options.card.title
                    : null,
            },
            MuiDialogContent: {
                root: options.hasOwnProperty("dialog")
                    ? options.dialog.content
                    : null,
            },
            MuiDialogTitle: {
                root: options.hasOwnProperty("dialog")
                    ? options.dialog.title
                    : null,
            },
            MuiDialogActions: {
                root: options.hasOwnProperty("dialog")
                    ? options.dialog.content
                    : null,
            },
            MuiTypography: {
                colorTextPrimary: options.hasOwnProperty("typography")
                    ? options.typography.primary
                    : null,
                colorTextSecondary: options.hasOwnProperty("typography")
                    ? options.typography.secondary
                    : null,
            },
            MuiButton: {
                textPrimary: options.hasOwnProperty("button")
                    ? options.button.textPrimary || {color: "#8cb557"}
                    : {color: "#8cb557"},
                containedPrimary: {
                    backgroundColor: "#8cb557",
                    '&:hover': {
                        backgroundColor: "#3B9684",
                    },
                    ...(options.hasOwnProperty("button")
                        ? options.button.primary || {}
                        : {}),
                },
                root: {
                    borderRadius: "0.5rem",
                },
            },
            MuiSvgIcon: {
                colorPrimary: {
                    color: "#8cb557",
                },
            },
            MuiCircularProgress: {
                colorSecondary: {
                    color: "#8cb557",
                },
            },
            MuiBadge: {
                colorPrimary: {
                    backgroundColor: "#8cb557",
                    color: "#fff",
                    ...(options.hasOwnProperty("button")
                        ? options.button.primary || {}
                        : {}),
                },
            },
            MuiChip: {
                colorPrimary: {
                    backgroundColor: "#8cb557",
                    color: "#fff",
                    ...(options.hasOwnProperty("button")
                        ? options.button.primary || {}
                        : {}),
                },
                clickableColorPrimary: options.hasOwnProperty("button")
                    ? options.button.primary
                    : null,
            },
            MuiStepper: {
                root: {
                    background: "none",
                    paddingLeft: 0,
                    paddingRight: 0,
                },
            },
            MuiStepIcon: {
                completed: {
                    color: "#3B9684 !important",
                    ...(options.hasOwnProperty("step")
                        ? options.step.icon.completed || {}
                        : {}),
                },
                active: {
                    color: "#333 !important",
                    ...(options.hasOwnProperty("step")
                        ? options.step.icon.active || {}
                        : {}),
                },
                text: options.hasOwnProperty("step")
                    ? options.step.text || null
                    : null,
            },
            MuiStepLabel: {
                completed: {
                    color: "#3B9684 !important",
                    ...(options.hasOwnProperty("step")
                        ? options.step.label.completed || {}
                        : {}),
                },
                active: {
                    color: "#333 !important",
                    ...(options.hasOwnProperty("step")
                        ? options.step.label.active || {}
                        : {}),
                },
            },
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: "#58595B",
                    ...(options.hasOwnProperty("calendar")
                        ? options.calendar.header || {}
                        : {}),
                },
            },
            MuiPickersBasePicker: {
                pickerView: options.hasOwnProperty("calendar")
                    ? options.calendar.content || {}
                    : {},
            },
            MuiPickersCalendarHeader: {
                iconButton: options.hasOwnProperty("calendar")
                    ? options.calendar.button || {}
                    : {},
            },
            MuiPickersDay: {
                daySelected: {
                    color: "#fff",
                    backgroundColor: "#8cb557",
                    '&:hover': {
                        backgroundColor: "#3B9684",
                    },
                    ...(options.hasOwnProperty("calendar")
                        ? options.calendar.daySelected || {}
                        : {}),
                },
                dayDisabled: options.hasOwnProperty("calendar")
                    ? options.calendar.dayDisabled || {}
                    : {},
                current: {
                    color: "#fff",
                    backgroundColor: "#8cb557",
                    ...(options.hasOwnProperty("calendar")
                        ? options.calendar.current || {}
                        : {}),
                },
            },
            MuiTableCell: {
                stickyHeader: {
                    backgroundColor: "#8cb557",
                    color: "#ffffff",
                },
            },
            MuiOutlinedInput: {
                root: {
                    borderRadius: "0.5rem",
                },
            },
            MuiFab: {
                secondary: {
                    backgroundColor: '#FF7700',
                    '&:hover': {
                        backgroundColor: '#D66F14'
                    },
                }
            }
        },
    });

const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
});

const LoggedApp = ({ handleReset }) => (
    <PusherProvider value={pusherClient}>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={handleReset}
            >
                <App />
            </ErrorBoundary>
        </MuiPickersUtilsProvider>
    </PusherProvider>
);
class Init extends React.Component {
    handleReset = () => {
        this.props.resetInMemoryStore();
        this.props.resetLocalStore();
    };

    render() {
        return (
            <MuiThemeProvider theme={theme(this.props.layout)}>
                <CssBaseline />
                {this.props.isLogged ? (
                    <LoggedApp handleReset={this.handleReset} />
                ) : (
                    <DomainAuthenticator />
                )}
            </MuiThemeProvider>
        );
    }
}

const mapLocalStateToProps = (state) => ({
    isLogged:
        state.oauth !== null && dayjs().isBefore(dayjs(state.oauth.expiresAt)),
});

const mapInMemoryStateToProps = (state) => ({
    layout: state.layout,
});

const mapInMemoryDispatchToProps = (dispatch) => ({
    resetInMemoryStore: () => dispatch({ type: "APP:RESET" }),
});

const mapLocalDispatchToProps = (dispatch) => ({
    resetLocalStore: () => dispatch({ type: "APP:RESET" }),
});

export default compose(
    connect(mapLocalStateToProps, mapLocalDispatchToProps, null, {
        context: LocalContext,
    }),
    connect(mapInMemoryStateToProps, mapInMemoryDispatchToProps, null, {
        context: InMemoryContext,
    })
)(Init);
