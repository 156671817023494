export class Category {
    _id;
    _sorting;
    _name;
    _media;
    _description

    constructor(id, sorting, name, media, description) {
        this._id = id;
        this._sorting = sorting;
        this._name = name;
        this._media = media;
        this._description = description;
    }

    get id() {
        return this._id;
    }

    get sorting() {
        return this._sorting;
    }

    get name() {
        return this._name;
    }

    get media() {
        return this._media;
    }

    get description() {
        return this._description;
    }
}

export class MenuItem {
    _id;
    _categoryId;
    _name;
    _description;
    _price;
    _vat;
    _compositions;
    _rules;
    _media;

    constructor(id, categoryId, name, description, price, vat, rules = [], compositions = [], media) {
        this._id = id;
        this._categoryId = categoryId;
        this._name = name;
        this._description = description;
        this._price = price;
        this._vat = vat;
        this._compositions = compositions;
        this._rules = rules;
        this._media = media;
    }

    get id() {
        return this._id;
    }

    get categoryId() {
        return this._categoryId;
    }

    get name() {
        return this._name;
    }

    get description() {
        return this._description;
    }

    get price() {
        return this._price;
    }

    get vat() {
        return this._vat;
    }

    get rules() {
        return this._rules.map(cr => new MenuItemCompositionRule(
            cr.id,
            cr.name,
            cr.description,
            cr.minItemCount,
            cr.maxItemCount
        ));
    }

    get compositions() {
        return this._compositions.map(c => new MenuItemComposition(
            c.type,
            c.ingredient.id,
            c.ingredient.name,
            c.priceImpact,
            c.rule,
        ));
    }

    get media() {
        return this._media;
    }
}

export class MenuItemComposition {
    _type;
    _ingredientId;
    _ingredientName;
    _priceImpact;
    _ruleId;

    constructor(type, ingredientId, ingredientName, priceImpact, ruleId) {
        this._type = type;
        this._ingredientId = ingredientId;
        this._ingredientName = ingredientName;
        this._priceImpact = priceImpact;
        this._ruleId = ruleId;
    }

    get type() {
        return this._type;
    }

    get ingredientId() {
        return this._ingredientId;
    }

    get ingredientName() {
        return this._ingredientName;
    }

    get priceImpact() {
        return this._priceImpact;
    }

    get ruleId() {
        return this._ruleId;
    }
}

export class MenuItemCompositionRule {
    _id;
    _name;
    _description;
    _minItemCount;
    _maxItemCount;

    constructor(id, name, description, minItemCount, maxItemCount) {
        this._id = id;
        this._name = name;
        this._description = description;
        this._minItemCount = minItemCount;
        this._maxItemCount = maxItemCount;
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get description() {
        return this._description;
    }

    get minItemCount() {
        return this._minItemCount;
    }

    get maxItemCount() {
        return this._maxItemCount;
    }
}