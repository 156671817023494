export const CommonFilters = {
    SET_POS_ID: 'LOCAL.COMMON.SET_POS_ID',
    UNSET_POS_ID: 'LOCAL.COMMON.UNSET_POS_ID',
    SET_MENU_ID: 'LOCAL.COMMON.SET_MENU_ID',
    UNSET_MENU_ID: 'LOCAL.COMMON.UNSET_MENU_ID',
    SET_ADDRESS_ID: 'LOCAL.COMMON.SET_ADDRESS_ID',
    UNSET_ADDRESS_ID: 'LOCAL.COMMON.UNSET_ADDRESS_ID',
    SET_CUSTOMER_ADDRESS_ID: 'LOCAL.COMMON.SET_CUSTOMER_ADDRESS_ID',
    UNSET_CUSTOMER_ADDRESS_ID: 'LOCAL.COMMON.UNSET_CUSTOMER_ADDRESS_ID',
    SET_WORKSHIFT_ID: 'LOCAL.COMMON.SET_WORKSHIFT_ID',
    UNSET_WORKSHIFT_ID: 'LOCAL.COMMON.UNSET_WORKSHIFT_ID',
    SET_MIN_ORDER_VALUE: 'LOCAL.COMMON.SET_MIN_ORDER_VALUE',
    SET_AVAILABLE_PAYMENT_METHODS: 'LOCAL.COMMON.SET_AVAILABLE_PAYMENT_METHODS',
    SET_ORDERING_MODES: 'LOCAL.COMMON.SET_ORDERING_MODES',
    SET_DELIVERY_TIME_RANGE: 'LOCAL.COMMON.DELIVERY_TIME_RANGE',
    SET_PAYPAL: 'LOCAL.COMMON.SET_PAYPAL',
    UNSET_PAYPAL: 'LOCAL.COMMON.UNSET_PAYPAL',
    SET_NEXI: 'LOCAL.COMMON.SET_NEXI',
    UNSET_NEXI: 'LOCAL.COMMON.UNSET_NEXI',
};

export const setPosId = id => ({
    type: CommonFilters.SET_POS_ID,
    id
});

export const unsetPosId = () => ({
    type: CommonFilters.UNSET_POS_ID
});

export const setMenuId = id => ({
    type: CommonFilters.SET_MENU_ID,
    id
});

export const unsetMenuId = () => ({
    type: CommonFilters.UNSET_MENU_ID
});

export const setAddressId = id => ({
    type: CommonFilters.SET_ADDRESS_ID,
    id
});

export const unsetAddressId = () => ({
    type: CommonFilters.UNSET_ADDRESS_ID
});

export const setCustomerAddressId = id => ({
    type: CommonFilters.SET_CUSTOMER_ADDRESS_ID,
    id
});

export const unsetCustomerAddressId = () => ({
    type: CommonFilters.UNSET_CUSTOMER_ADDRESS_ID
});

export const setWorkshiftId = id => ({
    type: CommonFilters.SET_WORKSHIFT_ID,
    id
});

export const unsetWorkshiftId = () => ({
    type: CommonFilters.UNSET_WORKSHIFT_ID
});

export const setMinOrderValue = value => ({
    type: CommonFilters.SET_MIN_ORDER_VALUE,
    value
});

export const setAvailablePaymetMethods = methods => ({
    type: CommonFilters.SET_AVAILABLE_PAYMENT_METHODS,
    methods
});

export const setOrderingModes = orderingModes => ({
    type: CommonFilters.SET_ORDERING_MODES,
    orderingModes
});

export const setDeliveryTimeRange = value => ({
    type: CommonFilters.SET_DELIVERY_TIME_RANGE,
    value
});

export const setPayPal = (clientId, checkout) => ({
    type: CommonFilters.SET_PAYPAL,
    clientId, checkout
});

export const unsetPayPal = () => ({
    type: CommonFilters.UNSET_PAYPAL
});

export const setNexi = (alias, url, env) => ({
    type: CommonFilters.SET_NEXI,
    alias, url, env
});

export const unsetNexi = () => ({
    type: CommonFilters.UNSET_NEXI
});