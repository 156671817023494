import React from "react";

const Checkout = React.lazy(() => import('./view/Order/Checkout/Checkout'));
const CheckoutAddress = React.lazy(() => import('./view/Order/Checkout/Address/Address'));
const CheckoutConfirm = React.lazy(() => import('./view/Order/Checkout/Confirm/Confirm'));
const CheckoutDeliveryTime = React.lazy(() => import('./view/Order/Checkout/DeliveryTime/DeliveryTime'));
const OrderBuilder = React.lazy(() => import('./view/Order/Builder/OrderBuilder'));
const OrderFinalized = React.lazy(() => import('./view/Order/Finalized/Finalized'));
const UserAccount = React.lazy(() => import('./view/User/Account/Account'));
const UserOrders = React.lazy(() => import('./view/User/Orders/MyOrders'));

export default [
    {
        path: '/',
        exact: true,
        component: OrderBuilder
    },
    {
        path: '/checkout',
        component: Checkout,
        routes: [
            {
                path: '/checkout/address',
                exact: true,
                component: CheckoutAddress
            },
            {
                path: '/checkout/delivery/time',
                exact: true,
                component: CheckoutDeliveryTime
            },
            {
                path: '/checkout/confirm',
                exact: true,
                component: CheckoutConfirm
            },
        ]
    },
    {
        path: '/account',
        exact: true,
        component: UserAccount
    },
    {
        path: '/my-orders',
        exact: true,
        component: UserOrders
    },
    {
        path: '/order/finalized',
        exact: true,
        component: OrderFinalized
    },
];