import {PosFilters} from '../../actions/inMemory/pos';

export default (state = null, action) => {
    const { type, ...data} = action;

    switch (type) {
        case PosFilters.SET_INFO:
            return { ...data };
        default:
            return state
    }
}
