import { Button, Link } from "@material-ui/core";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

const COOKIE_KEY = "cookieKey";
const COOKIE_LINK =
  "https://www.iubenda.com/privacy-policy/8283529/cookie-policy";

export const CookieBanner = () => {
  const { t } = useTranslation();
  const [showBanner, setShowBanner] = useState(
    (localStorage.getItem(COOKIE_KEY) ?? "false") === "false"
  );

  return showBanner ? (
    <StyledCookieBanner>
      <Title>
        <Trans ns="translation" i18nKey="cookie_policy.title" />
      </Title>
      <Body>
        <Trans ns="translation" i18nKey="cookie_policy.description" />
      </Body>
      <Footer>
        <Link href={COOKIE_LINK} target="_blank">
          <Trans ns="translation" i18nKey="cookie_policy.read_more" />
        </Link>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setShowBanner(false);
            localStorage.setItem(COOKIE_KEY, "true");
          }}
        >
          <Trans ns="translation" i18nKey="cookie_policy.confirm" />
        </Button>
      </Footer>
    </StyledCookieBanner>
  ) : (
    <></>
  );
};

const StyledCookieBanner = styled.div`
  background-color: #fff;
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  box-shadow: 0px -2px 6px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px -2px 6px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px -2px 6px 2px rgba(0, 0, 0, 0.1);
`;

const Title = styled.p`
  margin: 0;
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
`;

const Body = styled.p`
  margin: 0;
  padding: 1rem;
  padding-top: 0;
`;

const Footer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
`;
