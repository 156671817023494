export const MenuFilters = {
    SET_CATEGORIES: 'IN_MEMORY.MENU.SET_CATEGORIES',
    SELECT_CATEGORY: 'IN_MEMORY.MENU.SELECT_CATEGORY',
    UNSELECT_CATEGORY: 'IN_MEMORY.MENU.UNSELECT_CATEGORY',
    SET_ITEMS: 'IN_MEMORY.MENU.SET_ITEMS',
    SET_SEARCH_TERM: 'IN_MEMORY.MENU.SET_SEARCH_TERM',
};

export const setCategories = categories => ({
    type: MenuFilters.SET_CATEGORIES,
    categories
});

export const selectCategory = id => ({
    type: MenuFilters.SELECT_CATEGORY,
    id
});

export const unselectCategory = () => ({
    type: MenuFilters.UNSELECT_CATEGORY
});

export const setItems = items => ({
    type: MenuFilters.SET_ITEMS,
    items
});

export const setSearchTerm = term => ({
    type: MenuFilters.SET_SEARCH_TERM,
    term
});
