import {OrderFilters} from '../../actions/inMemory/order';

export default (state = null, action) => {
    const { type, ...data} = action;

    switch (type) {
        case OrderFilters.SET_IN_SYNC:
            return { ...state, inSync: data.inSync };

        default:
            return state
    }
}
