import {FlashMessagesFilters} from '../../actions/inMemory/flashMessages';

export default (state = [], action) => {
    const { type, ...data} = action;

    switch (type) {
        case FlashMessagesFilters.ADD:
            return [...state, { severity: data.severity, i18nKey: data.i18nKey }];
        default:
            return state
    }
}
